import React from "react";
import styles from "../../styles/styles";
import CountDown from "./CountDown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/actions/cart";
import { toast } from "react-toastify";

const EventCard = ({ active }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const events = useSelector((state) => state.events.allEvents);

  console.log(active);

  const addToCartHandler = (event) => {
    const isItemExists = cart && cart.find((item) => item._id === event._id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (event.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...event, qty: 1 };
        dispatch(addToCart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <div className="flex flex-wrap">
      {events.map((event) => (
        <div
          key={event._id}
          className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mb-4 p-2"
        >
          <div className="bg-white rounded-lg shadow-md p-4">
            <img
              style={{ width: "22rem", height: "23rem", objectFit: "cover" }}
              src={`${event.images[0]?.url}`}
              alt=""
            />
            <h2 className={`${styles.productTitle} mt-2`}>{event.name}</h2>
            <p>{event.description}</p>
            <div className="flex justify-between items-center mt-2">
              <div className="flex">
                <h5 className="font-[500] text-[18px] text-[#d55b45] pr-3 line-through">
                  {event.originalPrice}
                </h5>
                <h5 className="font-bold text-[20px] text-[#333] font-Roboto">
                  {event.discountPrice}
                </h5>
              </div>
              <span className="font-[400] text-[17px] text-[#44a55e]">
                {event.sold_out} sold
              </span>
            </div>
            <CountDown data={event} />
            <div className="flex mt-2">
              <Link to={`/product/${event._id}?isEvent=true`}>
                <div className={`${styles.button} text-[#fff] mr-2`}>
                  See Details
                </div>
              </Link>
              <div
                className={`${styles.button} text-[#fff]`}
                onClick={() => addToCartHandler(event)}
              >
                Add to cart
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventCard;
