import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import HomeEn from "./locales/en/home.json";
import HomeVi from "./locales/vi/home.json";
import HeaderEn from "./locales/en/header.json";
import HeaderVi from "./locales/vi/header.json";
import BrandEn from "./locales/en/brand.json";
import BrandVi from "./locales/vi/brand.json";
import CategoryEn from "./locales/en/categories.json";
import CategoryVi from "./locales/vi/categories.json";
import FooterEn from "./locales/en/footer.json";
import FooterVi from "./locales/vi/footer.json";
import AboutUsEn from "./locales/en/aboutUs.json";
import AboutUsVi from "./locales/vi/aboutUs.json";
import FaqEn from "./locales/en/faq.json";
import FaqVi from "./locales/vi/faq.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      home: HomeEn,
      header: HeaderEn,
      brand: BrandEn,
      categories: CategoryEn,
      aboutUs: AboutUsEn,
      faq: FaqEn,
      footer: FooterEn,
    },
    vi: {
      home: HomeVi,
      header: HeaderVi,
      brand: BrandVi,
      categories: CategoryVi,
      aboutUs: AboutUsVi,
      faq: FaqVi,
      footer: FooterVi,
    },
  },
  lng: "en", // Ngôn ngữ mặc định
  ns: ["home", "header", "brand", "categories", "aboutUs", "faq", "footer"], // Sử dụng namespace riêng biệt
  fallbackLng: "vi", // Ngôn ngữ dự phòng nếu ngôn ngữ hiện tại không có dịch
  interpolation: {
    escapeValue: false, // không chuyển đổi ký tự đặc biệt
  },
});

export default i18n;
