import React from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";
import { useTranslation } from "react-i18next";

const FeaturedProduct = () => {
  const allProducts = useSelector((state) => state.products.allProducts);

  const { t } = useTranslation("home");

  return (
    <div>
      <div className={`${styles.section} max-md:w-full`}>
        <div className={`${styles.heading}`}>
          <h1>{t("home.featuredProducts")}</h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-[15px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12 border-0 max-md:flex flex-wrap">
          {allProducts && allProducts.length !== 0 && (
            <>
              {allProducts &&
                allProducts.map((product, index) => (
                  <div
                    className={`max-md:w-1/2 max-md:p-1 ${
                      index % 2 === 0 ? "order-1" : "order-2"
                    }`}
                    key={index}
                  >
                    <ProductCard data={product} />
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturedProduct;
