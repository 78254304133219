import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { SiZalo } from "react-icons/si";
import { Link } from "react-router-dom";
// import {
//   footercompanyLinks,
//   footerSupportLinks,
// } from "../../static/data";
import { useTranslation } from "react-i18next";
import { BiLogoTiktok } from "react-icons/bi";
import { useFooterProductLinks } from "../../static/data";

const Footer = () => {
  const footerProductLinks = useFooterProductLinks();
  const { t } = useTranslation("footer");
  return (
    <>
      <div className="bg-[linear-gradient(133deg,_#566270_0%,_#283444_100%)] text-white">
        <div className="md:flex md:justify-between md:items-center sm:px-12 px-4 bg-[#342ac8] py-7">
          <h1 className="lg:text-4xl text-3xl md:mb-0 mb-6 lg:leading-normal md:w-2/5">
            <span className="text-[#56d879]">{t("footer.contact")}</span>{" "}
            {t("footer.withUs")} <br />
            {t("footer.eventAndOffers")}
          </h1>
          <div>
            <input
              type="text"
              required
              placeholder="Nhập Email Của Bạn..."
              className="text-gray-800
                sm:w-72 w-full sm:mr-5 mr-1 lg:mb-0 mb-4 py-2.5 rounded px-2 focus:outline-none"
            />
            <button className="bg-[#56d879] hover:bg-teal-500 duration-300 px-5 py-2.5 rounded-md text-whie md:w-auto w-full">
              Submit
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:gird-cols-3 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-8 sm:text-center">
          <ul className="px-5 text-center sm:text-start flex sm:block flex-col items-center">
            <img
              src="https://res.cloudinary.com/letiendat/image/upload/v1696695714/zomboss/oxq8gbynznu8brw2wk10.png"
              alt=""
              // style={{ filter: "brightness(0) invert(1)" }}
            />
            <br />
            <p>{t("footer.address")}</p>
            <div className="flex items-center mt-[15px]">
              <Link
                to="https://www.facebook.com/profile.php?id=100004377139298"
                target="_blank"
              >
                <AiFillFacebook size={25} className="cursor-pointer" />
              </Link>

              <Link to="https://www.zalo.me/0934106904" target="_blank">
                <SiZalo
                  size={25}
                  style={{ marginLeft: "15px", cursor: "pointer" }}
                />
              </Link>

              <Link to="https://www.tiktok.com/@attbp88" target="_blank">
                <BiLogoTiktok
                  size={25}
                  style={{ marginLeft: "15px", cursor: "pointer" }}
                />
              </Link>

              <Link to="https://wa.me/0934106904" target="_blank">
                <AiOutlineWhatsApp
                  size={25}
                  style={{ marginLeft: "15px", cursor: "pointer" }}
                />
              </Link>
            </div>
          </ul>

          <ul className="text-center sm:text-start">
            <h1 className="mb-1 font-semibold">Company</h1>
            {footerProductLinks.map((link, index) => (
              <li key={index}>
                <Link
                  className="text-gray-400 hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                  to={link.link}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>

          {/* <ul className="text-center sm:text-start">
            <h1 className="mb-1 font-semibold">Shop</h1>
            {footercompanyLinks.map((link, index) => (
              <li key={index}>
                <Link
                  className="text-gray-400 hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                  to={link.link}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>

          <ul className="text-center sm:text-start">
            <h1 className="mb-1 font-semibold">Support</h1>
            {footerSupportLinks.map((link, index) => (
              <li key={index}>
                <Link
                  className="text-gray-400 hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                  to={link.link}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul> */}

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3757.9671121131937!2d106.95766197483003!3d11.804560839389469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317369e7386f6837%3A0xcc41accd063cd942!2zQ8O0bmcgdHkgQVQmVCBCw4xOSCBQSMav4buaQw!5e1!3m2!1svi!2s!4v1697648882513!5m2!1svi!2s"
            width="500"
            height="250"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps"
            className="max-md:w-full max-md:h-auto"
          />
        </div>

        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10
         text-center pt-2 text-gray-400 text-sm pb-8"
        >
          <span> {t("footer.license")}</span>
          <span>{t("footer.termsPolicies")}</span>
          <div className="sm:block flex items-center justify-center w-full">
            <img
              src="https://hamart-shop.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffooter-payment.a37c49ac.png&w=640&q=75"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
