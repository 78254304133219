import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";

const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (categoryData === null) {
      const d = allProducts;
      setData(d);
    } else {
      const d =
        allProducts && allProducts.filter((i) => i.category === categoryData);
      setData(d);
    }
    //    window.scrollTo(0,0);
  }, [allProducts, categoryData]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="">
          <Header activeHeading={2} />
          <br />
          <br />
          <div className={`${styles.section} max-md:w-full`}>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-[15px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12 border-0 max-md:flex flex-wrap">
              {data &&
                data.map((i, index) => (
                  // <ProductCard data={i} key={index}/>
                  <div className={`max-md:w-1/2 max-md:p-1 mt-4`} key={index}>
                    <ProductCard data={i} />
                  </div>
                ))}

              {data && data.length === 0 ? (
                <h1 className="text-center w-full pb-[100px] pt-[100px] text-[20px]">
                  No products Found!
                </h1>
              ) : null}
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default ProductsPage;
