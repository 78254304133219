import React from "react";
import styles from "../../styles/styles";

const Sponsored = () => {
  return (
    <div
      className={` ${styles.section} 
             flex overflow-x-auto px-4 py-4 mb-12 cursor-pointer rounded-xl bg-white
      `}
    >
      <div className="flex items-center justify-between w-full space-x-4">
        <img
          src="https://www.palmetto-industries.com/wp-content/uploads/2022/04/BRCGS-Logo.png"
          alt="Logo 1"
          className="sm:w-32 sm:h-32 w-20 h-20 object-contain rounded-lg"
        />
        <img
          src="https://halal.vn/datafiles/setone/1582015726_Logo-chung-nhan-HALAL-HCA-Vietnam.jpg"
          alt="Logo 2"
          className="sm:w-32 sm:h-32 w-20 h-20 object-contain rounded-lg"
        />
        <img
          src="https://tqc.vn/pic/New/tieu-chua_637577097889303841.jpg"
          alt="Logo 3"
          className="sm:w-32 sm:h-32 w-20 h-20 object-cover rounded-lg"
        />
        <img
          src="https://kimmyfarm.com/wp-content/uploads/2022/01/iso-22000.png"
          alt="Logo 4"
          className="sm:w-32 sm:h-32 w-20 h-20 object-cover rounded-lg"
        />
        <img
          src="https://pbs.twimg.com/profile_images/1040674364079763456/obYWBuxM_400x400.jpg"
          alt="Logo 5"
          className="sm:w-32 sm:h-32 w-20 h-20 object-cover rounded-lg"
        />
      </div>
    </div>
  );
};

export default Sponsored;
