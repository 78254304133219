import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createProduct, updateProduct } from "../../redux/actions/product";
import { useCategoriesData } from "../../static/data";
import { toast } from "react-toastify";
import { server } from "../../server";

const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const allProducts = useSelector((state) => state.products.allProducts);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const categoriesData = useCategoriesData();
  const [images, setImages] = useState([]);
  // const [originalPrice, setOriginalPrice] = useState("");
  // const [stock, setStock] = useState("");

  const data = allProducts && allProducts.find((i) => i._id === id);

  useEffect(() => {
    // Nếu có id sản phẩm, hãy tải dữ liệu sản phẩm cần cập nhật
    if (id) {
      // Kiểm tra xem data có tồn tại không
      if (data) {
        setName(data.name);
        setDescription(data.description);
        setCategory(data.category);
        setTags(data.tags);
        setDiscountPrice(data.discountPrice);
        setImages(
          data.images.map((image) => ({
            public_id: image.public_id,
            url: image.url,
          }))
        );
      }
    }
  }, [id, data]);

  console.log(images);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Product created successfully!");
      navigate("/dashboard");
      window.location.reload();
    }
  }, [dispatch, error, success, navigate]);

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    try {
      const folderName = "Products";
      const formData = new FormData();
      formData.append("type", folderName);
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });

      const response = await axios.post(`${server}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const imageProduct = response.data.imageProduct.map((image) => ({
        public_id: image.public_id,
        url: image.url,
      }));

      setImages((prevImages) => [...prevImages, ...imageProduct]);
      toast.success("Hình ảnh đã được upload thành công");
    } catch (error) {
      console.error("Error uploading images:", error);
      toast.error("Error uploading images");
    }
  };

  const handleImageDelete = (public_id) => {
    const publicId = encodeURIComponent(public_id);
    axios
      .delete(`${server}/delete-image/${publicId}`)
      .then((response) => {
        if (response.status === 500) {
          toast.error(response.data.msg);
        } else {
          toast.success(response.data.msg);
          const updatedImages = images.filter(
            (image) => image.public_id !== public_id
          );
          setImages(updatedImages);
        }
      })
      .catch((error) => {
        // Xóa ảnh gặp lỗi, xử lý lỗi nếu cần
        toast.error(error);
        console.error("Lỗi xóa ảnh: ", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const productData = {
      name,
      description,
      category,
      tags,
      discountPrice,
      shopId: seller._id,
      images: images.map((image) => ({
        public_id: image.public_id,
        url: image.url,
      })),
    };

    // Kiểm tra xem có ID sản phẩm (để xác định sản phẩm cần cập nhật) hoặc không (để tạo sản phẩm mới)
    if (id) {
      console.log(productData);
      dispatch(updateProduct(id, productData));
    } else {
      // Nếu không có ID, thì đây là việc tạo sản phẩm mới (createProduct)
      dispatch(createProduct(productData));
    }
  };

  return (
    <div className="w-full max-w-screen-xl bg-white shadow-lg rounded-lg p-4 md:p-8 lg:p-12">
      <h5 className="text-2xl font-Poppins text-center mb-4">Create Product</h5>
      <form onSubmit={handleSubmit}>
        <div className="md:grid md:grid-cols-2 md:gap-4">
          <div className="mb-4 md:mb-0">
            <label className="block pb-2">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your product name..."
            />
          </div>
          <div>
            <label className="block pb-2">
              Description <span className="text-red-500">*</span>
            </label>
            <textarea
              required
              rows="4"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your product description..."
            ></textarea>
          </div>
          <div>
            <label className="block pb-2">
              Category <span className="text-red-500">*</span>
            </label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="Choose a category">Choose a category</option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
          </div>
          <div className="mb-4 md:mb-0">
            <label className="block pb-2">Tags</label>
            <input
              type="text"
              name="tags"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your product tags..."
            />
          </div>
          {/* <div>
            <label className="block pb-2">Original Price</label>
            <input
              type="text"
              name="price"
              value={originalPrice}
              onChange={(e) => setOriginalPrice(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your product price..."
            />
          </div> */}
          <div>
            <label className="block pb-2">
              Price (With Discount) <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="price"
              value={discountPrice}
              onChange={(e) => setDiscountPrice(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your product price with discount..."
            />
          </div>
          {/* <div>
            <label className="block pb-2">
              Product Stock <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="price"
              value={stock}
              onChange={(e) => setStock(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your product stock..."
            />
          </div> */}
          <div>
            <label className="block pb-2">
              Upload Images <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              name=""
              id="upload"
              className="hidden"
              multiple={true}
              onChange={handleImageChange}
            />
            <div className="flex items-center space-x-2">
              <label htmlFor="upload" className="cursor-pointer">
                <AiOutlinePlusCircle size={30} className="text-gray-600" />
              </label>
              {images === null ? (
                <AiOutlineCloseCircle />
              ) : (
                images.map((image, index) => (
                  <div className="relative" key={index}>
                    <img
                      src={image.url}
                      alt=""
                      className="w-20 h-20 object-cover rounded m-1"
                    />
                    <AiOutlineCloseCircle
                      className="cursor-pointer absolute top-0 right-0"
                      onClick={() => handleImageDelete(image.public_id)}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="mt-4">
          <input
            type="submit"
            value="Create"
            className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 cursor-pointer"
          />
        </div>
      </form>
    </div>
  );
};

export default CreateProduct;
