import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import styles from "../styles/styles";
import { AiOutlineGlobal, AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FaRegAddressCard } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Hero from "../components/Route/Hero/Hero";

const AboutUs = () => {
  return (
    <div>
      <Header activeHeading={4} />
      <Hero />
      <About />
      <Footer />
    </div>
  );
};

const About = () => {
  const { t } = useTranslation("aboutUs");

  return (
    <>
      {/* <div
        className={`relative min-h-[30vh] 800px:min-h-[65vh] w-full max-md:mt-[4rem] bg-no-repeat bg-cover transition duration-1000 transform ${styles.noramlFlex}`}
        style={{
          backgroundImage:
            "url(https://res.cloudinary.com/letiendat/image/upload/v1696695862/zomboss/lmwonazcbjgpqu11ymht.jpg)",
        }}
      >
        <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
          <h1
            className={`text-[35px] text-[#166d16] leading-[1.2] 800px:text-[60px] font-extrabold  capitalize`}
            style={{
              textShadow: `1px 1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, -1px -1px 0 #ffffff`,
            }}
          >
            AT&T Bình Phước
          </h1>

          <div className="absolute h-[50px] xs:h-[150px] bottom-0 w-full bg-[linear-gradient(180deg,_#ffffff00_0%,_#FFF_100%)] left-0" />
        </div>
      </div> */}

      <div className="bg-[#ffffff] p-4 md:p-8 lg:px-22 flex flex-col md:flex-row items-center justify-between">
        <p className="w-full md:w-1/2 pt-5 text-[18px] font-[500] text-[#000000ba] pr-2 md:pr-4 text-justify ml-[3rem] max-md:ml-[0]">
          {t("aboutUs.descAboutUs")}
        </p>

        <img
          src="https://res.cloudinary.com/letiendat/image/upload/v1710396952/zomboss/mp5woawmvcgmez5mzw5b.jpg"
          alt=""
          className="w-[40%] max-w-full h-auto max-h-[30rem] rounded mr-[3rem] max-md:w-full max-md:mr-0 max-md:mt-5"
        />
      </div>

      <div className="hidden md:flex justify-center items-center mt-10 mb-10">
        <div className="overflow-hidden shadow-lg w-[35rem] h-[20rem] flex items-center justify-center bg-white rounded-lg mr-10">
          <img
            className="w-[20rem] h-[12rem] object-center"
            src="https://res.cloudinary.com/letiendat/image/upload/v1697438939/zomboss/xi6hsxes8asytgpnm48m.jpg"
            alt="Business Card"
          />
        </div>

        <div className="overflow-hidden shadow-lg w-[35rem] h-[20rem] flex items-center justify-center bg-white rounded-lg">
          <div className="flex w-1/2">
            <div className="flex flex-col items-center">
              <img
                className="w-[7rem] h-[5rem] object-center pt-2"
                src="https://res.cloudinary.com/letiendat/image/upload/v1697438939/zomboss/xi6hsxes8asytgpnm48m.jpg"
                alt="Business Card"
              />
              <div className="mt-4 pl-4">
                <div className="font-bold text-xl mb-1">
                  {t("aboutUs.career")}
                </div>
                <p className="text-gray-700 text-sm">
                  {t("aboutUs.careerFirst")}
                </p>
                <p className="text-gray-700 text-sm">
                  {t("aboutUs.careerSecond")}
                </p>
                <p className="text-gray-700 text-sm">
                  {t("aboutUs.careerTue")}
                </p>
              </div>
            </div>
          </div>

          <div className="flex w-1/2 flex-col pl-3">
            <h2 className="text-2xl text-[#112e0b] mb-1">
              {t("aboutUs.name")}
            </h2>
            <hr className="bg-[#70707055] h-1 my-1" />
            <span className="text-sm font-semibold mb-2">
              {t("aboutUs.position")}
            </span>
            <div className="flex flex-col mt-7">
              <div className="flex items-center mb-2">
                <div className="bg-green-500 rounded-full p-2">
                  <AiOutlinePhone className="text-white" />
                </div>
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 ml-2">
                  0934106904
                </span>
              </div>

              <div className="flex items-center mb-2">
                <div className="bg-green-500 rounded-full p-2">
                  <AiOutlineMail className="text-white" />
                </div>
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 ml-2">
                  att.bpfood@gmail.com
                </span>
              </div>

              <div className="flex items-center mb-2">
                <div className="bg-green-500 rounded-full p-2">
                  <AiOutlineGlobal className="text-white" />
                </div>
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 ml-2">
                  www.attbpfjsc.vn
                </span>
              </div>

              <div className="flex items-center">
                <div className="bg-green-500 rounded-full p-2">
                  <FaRegAddressCard className="text-white" />
                </div>
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 ml-2">
                  {t("aboutUs.address")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="md:hidden flex flex-col mb-10">
        <div className="overflow-hidden shadow-lg w-full flex justify-center bg-white rounded-lg">
          <div className="flex flex-col ">
            <h2 className="text-2xl text-[#112e0b] mx-auto mb-1 mt-5">
              {t("aboutUs.name")}
            </h2>
            <span className="text-sm font-semibold mx-auto mb-2">
              {" "}
              {t("aboutUs.position")}
            </span>
            <div className="mt-4 pl-4">
              <div className="font-bold text-xl mb-1">
                {t("aboutUs.career")}
              </div>
              <p className="text-gray-700 text-sm mb-2">
                {t("aboutUs.careerFirst")}
              </p>
              <p className="text-gray-700 text-sm mb-2">
                {t("aboutUs.careerSecond")}
              </p>
              <p className="text-gray-700 text-sm mb-2">
                {t("aboutUs.careerTue")}
              </p>
            </div>
            <div className="flex flex-col pl-4 mt-7 mb-7">
              <div className="flex mb-2">
                <div className="bg-green-500 rounded-full p-2">
                  <AiOutlinePhone className="text-white" />
                </div>
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 ml-2">
                  0934106904
                </span>
              </div>

              <div className="flex mb-2">
                <div className="bg-green-500 rounded-full p-2">
                  <AiOutlineMail className="text-white" />
                </div>
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 ml-2">
                  att.bpfood@gmail.com
                </span>
              </div>

              <div className="flex mb-2">
                <div className="bg-green-500 rounded-full p-2">
                  <AiOutlineGlobal className="text-white" />
                </div>
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 ml-2">
                  www.attbpfjsc.vn
                </span>
              </div>

              <div className="flex mb-2">
                <div className="bg-green-500 rounded-full p-2">
                  <FaRegAddressCard className="text-white" />
                </div>
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 ml-2">
                  {t("aboutUs.address")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
