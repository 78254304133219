import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation("home");

  return (
    <div
      className={`relative min-h-[35vh] 800px:min-h-[53vh] w-full bg-cover bg-center max-md:mt-[4rem] bg-slate-400 mb-3 ${styles.noramlFlex}`}
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/letiendat/image/upload/v1696695862/zomboss/lmwonazcbjgpqu11ymht.jpg)",
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%] max-md:mt-2`}>
        <h1
          className={`text-[35px] text-[#166d16] leading-[1.2] 800px:text-[60px] font-extrabold capitalize max-md:mt-14`}
          style={{
            textShadow: `1px 1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, -1px -1px 0 #ffffff`,
          }}
        >
          {t("home.title")}
        </h1>
        <p
          className="pt-5 text-[16px] font-[400] text-[#ffffff] max-md:text-sm text-xl"
          style={{
            textShadow: `1px 1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, -1px -1px 0 #000000`,
          }}
        >
          {t("home.description")}
          <br />
        </p>
        <Link to="/products" className="inline-block max-md:hidden ">
          <div className={`${styles.button} mt-5`}>
            <span className="text-[#fff] font-[Poppins] text-[18px]">
              Shop Now
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
